.card-tets {
  background-color: orange;
}

.cards-header {
  height: 200px;
  max-width: 1440px;
  width: 100%;
  background: #ffffff;
  /* тень осн */

  box-shadow: 0px 4px 30px rgba(0, 0, 0, 0.15);
  border-radius: 0 0 40px 40px;
  padding: 20px;

  top: 100px;

  &-title {
    margin-bottom: 20px;
    font-style: normal;
    font-weight: 700;
    font-size: 24px;
    line-height: 20px;

    color: #202328;
  }

  &-wrapper {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 20px;

    &-card {
      display: flex;
      gap: 20px;
      min-height: 110px;
      flex: 1;
      overflow: hidden;
    }
  }
}

.cards-header-swapper-buttons-item {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 32px;
  min-width: 32px;
  height: 32px;
  background-color: #fd633d;
  border-radius: 100%;
  cursor: pointer;

  &-top {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-left: 3px;
  }

  &-bottom {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-left: -3px;
  }
}

.top {
  z-index: 1000;
  top: 0;
  position: fixed;
}
