.drop-down-check-box-button__wrap {
    position: relative;

    &-main {
        cursor: pointer;
        min-width: 65px;

        display: flex;
        justify-content: space-between;
        align-items: center;

        padding: 0 20px;

        width: 175px;
        height: 56px;
        left: 20px;
        top: 22px;

        background: #37383D;
        border-radius: 100px;

        h5 {
            font-style: normal;
            font-weight: 600;
            font-size: 16px;
            line-height: 20px;
            color: #FFFFFF;
        }
    }

    &-drop-wrap{
        z-index: 10000;
        bottom: -240px;
        position: absolute;
        width: 312px;
        height: 229px;
        display: flex;
        align-items: flex-start;
        flex-direction: column;
        justify-content: space-between;
        gap: 10px;
        padding: 10px 10px;
        background: #FFFFFF;
        border: 1px solid #FD633D;
        box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.05);
        border-radius: 8px;

        &-list {
            width: 100%;
            padding: 10px 0;


            overflow-y: scroll;
            scrollbar-width: 4px; /* Для Firefox */
            -ms-overflow-style: none; /* Для IE и Edge */

            li {
                display: flex;
                flex-direction: row;
                gap: 5px;
                align-items: center;

                width: 100%;
                padding: 0 10px;
                height: 37px;
                cursor: pointer;
                font-style: normal;
                font-weight: 400;
                font-size: 14px;
                line-height: 21px;

                color: #212429;
                letter-spacing: 0.02em;

            }
        }

        &-clear{

            display: flex;
            align-items: flex-start;
            justify-content: center;
            margin: 0 auto;
            gap: 20px;

            &-add{
                cursor: pointer;
                display: flex;
                justify-content: center;
                align-items: center;
                width: 100px;
                height: 25px;
                border-radius: 100px;

                background-color: #fff;

                border: 2px solid #ff6e47;
                font-style: normal;
                font-weight: 700;
                font-size: 12px;
                line-height: 20px;

                color: #ff6e47;
            }
            &-clear{
                cursor: pointer;
                display: flex;
                justify-content: center;
                align-items: center;
                width: 100px;
                height: 25px;
                border-radius: 100px;
                background-color: #ff6e47;

                font-style: normal;
                font-weight: 700;
                font-size: 12px;
                line-height: 20px;

                color: #FFFFFF;
            }
        }

    }
}

.active{
    background-color: rgba(253,99,61, 0.3);
}
.checkbox{
    background-color: #7FEDBB;
}

.drop-down-check-box-button__wrap-drop-wrap-clear-add:disabled,
button[disabled]{
    background-color: #ffffff !important;
    color: #ff6e47;
    opacity: 0.6;
}

.drop-down-check-box-button__wrap-drop-wrap-clear-clear:disabled,
button[disabled]{
    opacity: 0.6;
    color: #fff;
}

.drop-down-check-box-button__wrap-drop-wrap-list::-webkit-scrollbar {
    width: 4px;
}

.drop-down-check-box-button__wrap-drop-wrap-list::-webkit-scrollbar-track {
    background: #f1f1f1;
    border-radius: 10px;
    margin-left: 10px;
}

.drop-down-check-box-button__wrap-drop-wrap-list::-webkit-scrollbar-thumb {
    background: #FD633D;
    border-radius: 10px;
}

.drop-down-check-box-button__wrap-drop-wrap-list::-webkit-scrollbar-thumb:hover {
    background: #FD633D ;
    border-radius: 10px;
}