.dates-block {
  padding: 20px;
  max-width: 1440px;
  width: 100%;

  &-header {
    display: flex;
    flex-direction: column;
    &-title {
      margin-bottom: 20px;

      font-style: normal;
      //font-weight: 700;
      //font-size: 24px;
      font-size: 21px;
      line-height: 24px;
      color: #202328;
    }

    &-bold-font {
      font-weight: 700;
    }

    &-buttons {
      display: flex;
      gap: 5px;
      align-items: center;

      &-item {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 32px;
        min-width: 32px;
        height: 32px;
        background-color: #fd633d;
        border-radius: 100%;
        cursor: pointer;

        &-top {
          display: flex;
          justify-content: center;
          align-items: center;
          margin-left: -3px;
        }

        &-bottom {
          display: flex;
          justify-content: center;
          align-items: center;
          margin-left: 3px;
        }
      }
    }
  }

  &-dates-wrap {
    display: grid;
    grid-template-columns: repeat(7, 1fr); /* 7 элементов на строку */
    grid-template-rows: repeat(2, 1fr); /* 2 строки */
    gap: 18px;

    &-loading {
      margin-top: 200px;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }

    &-item {
      position: relative;
      padding: 10px;
      max-width: 184px;
      border: 1px solid #f3f3f3;
      border-radius: 20px;
      position: relative;
      padding: 10px;
      //min-height: 441px;
      background: #ffffff;
      border: 1px solid #f3f3f3;
      border-radius: 20px;

      display: flex;
      flex-direction: column;
      gap: 5px;

      overflow: hidden;
      display: flex;
      flex-direction: column;
      gap: 5px;

      &-date {
        margin-bottom: 10px;
        display: flex;
        align-items: center;

        font-weight: 500;

        background: #ffffff;
        /* box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1); */
        border-radius: 10px;
        width: 20%;
        padding: 10px 20px;
        margin-top: 30px;

        &-scroll {
          overflow-y: auto;
          overflow-x: hidden;
          height: 350px;

          &-time-wrap {
            display: flex;

            &-hour {
              font-style: normal;
              font-weight: 600;
              font-size: 12px;
              line-height: 20px;
              min-width: 32px;
              color: #202328;
            }

            &-line {
              margin-left: 7px;
              width: 100%;
              border-bottom: 1px solid #000;
            }

            &-block {
              width: 100%;

              &-item {
                margin-left: 7px;
                padding: 5px;
                background: #ffffff;
                box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
                border-radius: 10px;
                width: 100%;
                max-width: 100%;
                min-width: calc(100% - 9px);
                color: #4c21ff;

                position: relative;

                h1 {
                  font-style: normal;
                  font-weight: 700;
                  font-size: 10px;
                  line-height: 20px;
                  color: #fd633d;
                }

                h2 {
                  font-style: normal;
                  font-weight: 500;
                  font-size: 8px;
                  line-height: 9px;

                  color: #37383d;
                }

                h3 {
                  font-style: normal;
                  font-weight: 700;
                  font-size: 8px;
                  line-height: 20px;
                  color: #1f2227;
                }

                h4 {
                  font-style: normal;
                  font-weight: 700;
                  font-size: 8px;
                  line-height: 20px;
                  color: #1f2227;
                  max-width: 90%;
                }

                h5 {
                  position: absolute;
                  bottom: 0;
                  right: 10px;

                  font-style: normal;
                  font-weight: 700;
                  font-size: 8px;
                  line-height: 20px;
                  color: #fd633d;
                }
              }
            }
          }
        }
      }
    }

    &-show-more {
      width: 200px;
      margin: 0 auto;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }
}

.masters-line {
  display: flex;
  align-items: center;
  overflow-y: auto;
  gap: 18px;
  width: 100%;
}

.master-info {
  background-color: rgb(255, 255, 255);
  border-radius: 12px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
  padding: 10px;
}

.next-date-block {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}

.text-not-found-master {
  text-align: center;
}

.date-block {
  display: flex;
  flex-direction: column;
}

.master-card-root {
  background-color: rgb(255, 255, 255);
  border-radius: 20px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
  padding: 10px;
  margin-top: 20px;
  width: 180px;
  flex-shrink: 0;
}
.title-all {
  color: #202328;
  font-size: 24px;
  font-weight: 700;
  margin-top: 26px;
}

.button-more-master {
  width: 20%;
  display: flex;
  margin-top: 30px;
  transform: translateX(200%);
}
.text-not-found-master {
  width: 350px;
  color: #202328;
  font-size: 24px;
  text-align: start;
}
