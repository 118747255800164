.instructions {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 30px;
    padding-top: 20px;

    &-search {
        margin-top: 20px;
    }

    &-instructions-wrap {
        margin-top: 20px;
        display: flex;
        flex-direction: column;
        gap: 20px;

        &-loader {
            margin-top: 200px;
            display: flex;
            justify-content: center;
            align-items: center;
        }

        &-empty {
            margin-top: 200px;
            height: 100%;
            display: flex;
            justify-content: center;
            align-items: center;
            flex-direction: column;
            gap: 10px;

            &-title {
                font-style: normal;
                font-weight: 700;
                font-size: 36px;
                line-height: 43px;
                text-align: center;
                color: #fd633d;
            }

            &-desc {
                font-style: normal;
                font-weight: 500;
                font-size: 18px;
                line-height: 200%;
                text-align: center;
                color: #37383d;
            }
        }
    }
}
