.text-area {
    width: 100%;
}

.text-area-title{
    font-style: normal;
    font-weight: 700;
    font-size: 12px;
    line-height: 18px;
    color: #202328;
    margin-bottom: 6px;
}

.text-area-main {
    width: 100%;

    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    line-height: 18px;

    color: #202328;

    background: #FFFFFF;
    border: 1px solid #F3F3F3;
    border-radius: 4px;
    padding: 6px 12px;

    overflow: auto;
    resize: none;
}

