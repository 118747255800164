.input{

}

.input-title{
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    line-height: 17px;
    color: #1F2227;
    margin-bottom: 5px;
    padding: 0px 3px;
}

.input__wrap-login{
    display: flex;
    align-items: center;
    padding: 0px 3px;
    background: #fff;
    height: 40px;
    border-bottom: 1px solid #37383D;
}

.input__input{
    font-family: 'Gilroy', sans-serif;

    height: 20px;
    font-weight: 400;
    flex: 1;
    background: transparent;
    font-style: normal;
    font-size: 16px;
    line-height: 140%;
    letter-spacing: 0.02em;

    color: #37383D;
}

.input__input::placeholder{
    font-family: 'Gilroy', sans-serif;
    font-style: normal;
    font-size: 16px;
    line-height: 140%;
    letter-spacing: 0.02em;
    color: #37383D;
}

.input .input__input:-webkit-autofill {
    transition: background-color 60000000s 0s, color 60000000s 0s;
}

.input__left{
    width: 24px;
    height: 24px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
}

.input__right{
    width: 20px;
    height: 20px;
    margin-left: 12px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.input__right_clicked{
    cursor: pointer;
}

.input__error{
    padding: 8px 3px 0px;
}

.input__error-text{
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 14px;
    color: #EB5757;
}
