.header {
 
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    height: 100px;
    min-width: 1020px;
    width: 100%;
    background: #1F2227;
    box-shadow: 1px 2px 11px rgba(148, 148, 148, 0.25);

    &-bckg{
        position: absolute;
        width: 100%;
        left: 0;
        right: 0;
        margin: 0 auto;
        top: 0;
        height: 100px;
        background: #1F2227;
        box-shadow: 1px 2px 11px rgba(148, 148, 148, 0.25);
    }
}

.header_right-bar {
    display: flex;
    flex-direction: row;
    align-items: center;
    z-index: 100;

    &-logout{
        margin-left: 10px;
        width: 60px;
        height: 56px;
        background: #37383D;
        border-radius: 30px;

        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
    }
}

.header_left-bar {
    margin-right: 10px;
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 10px;
    z-index: 100;
}