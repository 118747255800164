.order-modal {
  height: 100vh;
  width: 100vw;
  background: rgba(0, 0, 0, 0.6);
  position: fixed;
  top: 0;
  left: 0;
  z-index: 100000;

  display: flex;
  justify-content: center;
  align-items: center;
  transition: 0.5s;
  opacity: 1;
  pointer-events: all;

  &-content {
    min-height: 200px;
    min-width: 100px;
    max-width: 633px;
    max-height: 600px;
    margin: 0 15px;
    width: 100%;

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 20px;

    background: #ffffff;
    box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.05);
    border-radius: 8px;

    padding: 44px 20px;

    position: relative;

    &-column {
      overflow: auto;
    }

    &-inputs {
      padding: 0 20px;
      width: 100%;
      display: flex;
      flex-direction: row;
      gap: 10px;
      //overflow: auto;
      //overflow: hidden;

      &-column {
        width: 50%;
        display: flex;
        flex-direction: column;
        gap: 8px;
      }
    }

    &-button {
      width: 180px;
    }

    &-close {
      cursor: pointer;
      position: absolute;
      top: 20px;
      right: 20px;
    }

    &-text {
      font-style: normal;
      font-weight: 700;
      font-size: 24px;
      line-height: 20px;
      color: #202328;
    }
  }
}
