.modal {
  align-items: center;
  position: fixed;
  justify-content: center;
  align-self: center;
  top: 50%;
  transform: translateY(-50%);
  z-index: 1000000000000;
  border: 3px solid black;
}

.img__modal-wr {
  min-width: 700px;
  min-height: 500px;
  max-width: 1250px;
  max-height: 780px;
}
