.navbar {
    display: flex;
    gap: 10px;

    &__item {
        padding: 18px 20px;
        background-color: #FF6E47;
        border-radius: 40px;
        position: relative;
        cursor: pointer;

        &__link {
            font-style: normal;
            font-weight: 700;
            font-size: 20px;
            line-height: 20px;
            color: #FFFFFF;
            display: flex;
            justify-content: center;
        }

        &-count{
            position: absolute;
            top: -4px;
            right: -4px;

            width: 26px;
            height: 26px;
            border-radius: 100px;
            background-color: #ffffff;

            display: flex;
            justify-content: center;
            align-items: center;

            font-style: normal;
            font-weight: 600;
            font-size: 20px;
            line-height: 24px;
            letter-spacing: 0.05em;
            color: #FF6E47;
        }
    }
}

@media (max-width: 1260px){
    .navbar {
        &__item {
            &__link {
                font-size: 18px;
            }
        }
    }

}
@media (max-width: 1150px){
    .input-search {
        width: 250px;
    }
}
