.header-card {
    &-item {
        min-width: 251px;
        max-width: 251px;
        width: 251px;

        min-height: 120px;
        max-height: 120px;
        height: 120px;


        padding: 8px 20px;

        background: #6FCF97;
        box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.06);
        border-radius: 20px;

        display: flex;
        flex-direction: column;
        gap: 4px;

        &-big-text {
            font-style: normal;
            font-weight: 700;
            font-size: 18px;
            line-height: 20px;
            color: #FFFFFF;
        }

        &-small-text {
            font-style: normal;
            font-weight: 500;
            font-size: 12px;
            line-height: 14px;
            color: #FFFFFF;
        }
    }
}