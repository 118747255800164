.input-order{

}

.input-order-title{
    font-style: normal;
    font-weight: 700;
    font-size: 12px;
    line-height: 18px;
    color: #202328;
    margin-bottom: 6px;
}

.input-order__wrap-login{
    background: #FFFFFF;
    border: 1px solid #F3F3F3;
    border-radius: 4px;
    display: flex;
    align-items: center;
    padding: 6px 12px;
}

.input-order__input{
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    line-height: 18px;
    color: #202328;

    height: 20px;
    flex: 1;
    background: transparent;
    letter-spacing: 0.02em;
}

.input-order__input::placeholder{
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    line-height: 18px;
    color: #202328;
}

.input-order .input-order__input:-webkit-autofill {
    transition: background-color 60000000s 0s, color 60000000s 0s;
}

.input__right_clicked{
    cursor: pointer;
}

.input__error{
    padding: 8px 3px 0px;
}

.input__error-text{
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 14px;
    color: #EB5757;
}
