.root {
  width: 100%;
  margin-top: 30px;
  display: flex;
  flex-direction: column;
  gap: 40px;
}

.content {
  padding: 30px;
  display: flex;
  flex-direction: column;
  gap: 20px;
  background: #ffffff;
  box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.3);
  border-radius: 8px;
}
.root figure table {
  width: 100%;
  border-collapse: collapse;
  border: 1px solid #ddd;
  margin-top: 20px;
}

.root figure th,
.root figure td {
  border: 1px solid #ddd;
  padding: 8px;
  text-align: left;
}

.root figure th {
  background-color: #f2f2f2;
}
.root figure img {
  width: 100%;
  margin-top: 30px;
}
.content img {
  width: 100%;
  margin-top: 30px;
}
