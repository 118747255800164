.alert-notification {
    position: fixed;
    top: 40px;
    right: 20px;
    max-width: 50%;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    z-index: 100000000;
}

.alert-notification-item {
    padding: 20px;
    display: flex;
    align-items: center;
    min-width: 280px;
	z-index: 1000000;

	border: 1px solid #FFFFFF;
	backdrop-filter: blur(15px);
	border-radius: 12px;

	position: relative;
}

.alert-notification-item.success {
    background-color: #6FCE96;
}

.alert-notification-item.error {
    background-color: #EB5757
}

.alert-notification-item.warning {
    background-color: #FD633D
}

.alert-notification-item__title {
	font-style: normal;
	font-weight: 700;
	font-size: 18px;
	line-height: 22px;
	color: #FFFFFF;
}

.alert-notification-item-data{
	display: flex;
	flex-direction: column;
}

.alert-notification-item__text {
	font-style: normal;
	font-weight: 500;
	font-size: 16px;
	line-height: 19px;
	color: #FFFFFF;
}

.alert-notification-item__circle{
	position: absolute;
	top: 10px;
	right: 10px;

	cursor: pointer;

}