.instruction-item {
  width: 100%;
  padding: 30px;

  background: #ffffff;
  box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.3);
  border-radius: 8px;

  display: flex;
  flex-direction: column;
  gap: 20px;

  &-title {
    display: flex;
    width: 100%;
    justify-content: space-between;
    gap: 10px;
    cursor: pointer;

    &-text {
      font-style: normal;
      font-weight: 700;
      font-size: 18px;
      line-height: 22px;
      color: #1f2227;
    }
  }

  &-desc {
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    color: #000000;
  }
}
.instruction-item-instruction {
  color: #3235be;
  cursor: pointer;
  font-size: 18px;
}
.instruction-item-instruction:hover {
  transition: 0.2ms ease-in-out;
  color: #2325be;
  text-decoration: underline;
}
