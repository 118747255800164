.back-button{
    width: 100px;

    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    cursor: pointer;

    &-icon{
        margin-top: 3px;
    }
    &-text{
        margin-left: 8px;
        font-style: normal;
        font-weight: 700;
        font-size: 24px;
        line-height: 20px;

        color: #202328;

    }
}