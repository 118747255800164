.drop-down-button__wrap {
    position: relative;

    &-main {
        cursor: pointer;
        display: flex;
        justify-content: space-between;
        align-items: center;

        padding: 0 20px;

        width: 100%;
        max-width: 312px;
        min-width: 250px;
        height: 56px;
        left: 20px;
        top: 22px;

        background: #37383D;
        border-radius: 100px;

        h5 {
            font-style: normal;
            font-weight: 600;
            font-size: 16px;
            line-height: 20px;
            color: #FFFFFF;
        }
    }

    &-drop-wrap {
        z-index: 10000;
        position: absolute;

        bottom: -240px;
        width: 312px;
        height: 229px;

        display: flex;
        align-items: flex-start;
        flex-direction: column;
        gap: 10px;


        padding: 10px 10px;
        background: #FFFFFF;
        border: 1px solid #FD633D;
        box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.05);
        border-radius: 8px;

        &-list {
            width: 100%;
            padding: 10px 0;


            overflow-y: scroll;
            scrollbar-width: 4px; /* Для Firefox */
            -ms-overflow-style: none; /* Для IE и Edge */


            li {
                display: flex;
                align-items: center;

                height: 37px;
                width: 100%;
                padding: 0 10px;
                cursor: pointer;
                font-style: normal;
                font-weight: 400;
                letter-spacing: 0.02em;
                font-size: 14px;
                line-height: 21px;
                color: #212429;
            }

            //
            //li:hover {
            //    color: #40A5F3;
            //}
        }

    }
}

.active {
    background-color: rgba(253, 99, 61, 0.3);
}

.drop-down-button__wrap-drop-wrap-list::-webkit-scrollbar {
    width: 4px;
}

.drop-down-button__wrap-drop-wrap-list::-webkit-scrollbar-track {
    background: #f1f1f1;
    border-radius: 10px;
    margin-left: 10px;
}

.drop-down-button__wrap-drop-wrap-list::-webkit-scrollbar-thumb {
    background: #FD633D;
    border-radius: 10px;
}

.drop-down-button__wrap-drop-wrap-list::-webkit-scrollbar-thumb:hover {
    background: #FD633D;
    border-radius: 10px;
}


@media (max-width: 1150px) {
    .drop-down-button__wrap {

        &-main {
            min-width: 150px;
        }
    }
}

