.checkbox{
	display: flex;
}

.checkbox__wrap{
	width: 16px;
	height: 16px;
	background: #ffff;
	border: 1px solid #202328;
	border-radius: 2px;
	cursor: pointer;
	display: flex;
	align-items: center;
	justify-content: center;
}

.checkbox__wrap_check{
	background: #FD633D;
	border: 1px solid #FD633D;
	border-radius: 2px;
}

.checkbox__check{
	width: 14px;
	height: 10px;
	fill: #FAF7F0;
}

