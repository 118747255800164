.input-search {
    width: 400px;
}

.input-search__wrap {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 56px;
    background: #37383D;
    border-radius: 100px;
    padding: 0 0 0 20px;
}

.input-search__input {
    flex: 1;
    background: transparent;
    letter-spacing: 0.02em;
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 20px;
    padding-right: 10px;

    color: #FFFFFF;
}

.input-search__input::placeholder {
    letter-spacing: 0.02em;
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 20px;
    color: #FFFFFF;
}

.input-search__input-dark {
    flex: 1;
    background: transparent;
    letter-spacing: 0.02em;
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 20px;

    color: #1F2227;
}

.input-search__input-dark::placeholder {
    letter-spacing: 0.02em;
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 20px;
    color: #1F2227;
}

.input .input__input:-webkit-autofill {
    transition: background-color 60000000s 0s, color 60000000s 0s;
}

.input-search__left {
    width: 24px;
    height: 24px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
}

.input-search__wrap-clear{
    cursor: pointer;

    padding-right: 8px;
    margin-top: 4px;
}

.input-search__wrap-search{
    width: 55px;
    height: 100%;
    background-color: #FD633D;
    border-top-right-radius: 100px;
    border-bottom-right-radius: 100px;
    cursor: pointer;

    padding-right: 5px;
    display: flex;
    justify-content: center;
    align-items: center;
}

@media (max-width: 1350px){
    .input-search {
        width: 300px;
    }
}
@media (max-width: 1150px){
    .input-search {
        width: 270px;
    }
}
