.login-wrapper{
    display: flex;
    flex: 1;
    justify-content: center;
    align-items: center;
    position: relative;
    &-form{
        padding: 0 44px;

        width: 60%;
        max-width: 520px;
        height: 610px;
        background: #FFFFFF;
        box-shadow: 0px 1px 20px rgba(0, 0, 0, 0.25);
        border-radius: 40px;
        display: flex;

        flex-direction: column;
        justify-content: center;
        align-items: center;


        &-title{
            font-style: normal;
            font-weight: 700;
            font-size: 40px;
            line-height: 48px;
            display: flex;
            align-items: flex-end;
            color: #FD633D;
            margin-bottom: 10px;
        }
        &-text{
            font-style: normal;
            font-weight: 400;
            font-size: 14px;
            line-height: 16px;
            color: #37383D;
            margin-bottom: 40px;
        }
        &-inputs{
            width: 100%;
            padding: 0 66px;
            display: flex;
            flex-direction: column;
            gap: 20px;
        }

    }
    &-plumb{
        width: 40%;
    }
    &-back1{
        position: absolute;
        top: 70px;
        right: 0;
        z-index: -10;
    }
    &-back2{
        position: absolute;
        bottom: 0px;
        left: 30px;
        z-index: -10;
    }
    &-back3{
        width: 400px;
        position: absolute;
        top: -40px;
        left: -60px;
        z-index: -10;
    }
}