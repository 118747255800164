.container {
  width: 100%;
  flex: 1;
  display: flex;
  flex-direction: column;
}

.main-header {
}

.main-wrapper {
  height: 100%;
  display: flex;
  flex-direction: row;
  flex: 1;
}

@media (max-width: 1150px) {
  .container {
    padding: 0 10px;
  }
}
